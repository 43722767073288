import { useState, useEffect, useContext } from "react";
import { Button } from "@material-tailwind/react";
import TransactionTable from "../components/shared/TransactionTable";
// import { claimActivity } from "../constants";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import { ReactComponent as Eth } from "../assets/eth-symbol.svg";
import { placeholderChartData } from "../constants";
import {
  useAccount,
  usePrepareContractWrite,
  useWaitForTransaction,
  useContractWrite,
} from "wagmi";
import { getUserShareClaimEvents } from "../hooks/FetchContractEvents";
import { getRevShareMerkleData } from "../firebase/FirebaseConfig";
import { getLeaf, generateMerkleProof } from "../lib/utils";
import { ethers } from "ethers";
import { DepositoryContract } from "../contracts/Depository";
import { formatEther } from "viem";
import { toast } from "sonner";
import axios from "axios";
import { DappContext } from "../context/Dapp.context";

const RevenueShare = () => {
  const [showInEth, setShowInEth] = useState(true);
  const [claimActivity, setClaimActivity] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [amountToClaim, setAmountToClaim] = useState("0.00");
  const [isClaimLive, setIsClaimLive] = useState(false);
  const [shareAlreadyClaimed, setShareAlreadyClaimed] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [proof, setProof] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [prepareError, setPrepareError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [chartData, setChartData] = useState([]);
  const [ethUsdPrice, setEthUsdPrice] = useState(0);

  const { address } = useAccount();
  const { wrongNetwork } = useContext(DappContext);

  useEffect(() => {
    setLoaded(false);
    const fetchData = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const depositoryContract = new ethers.Contract(
        DepositoryContract.address,
        DepositoryContract.abi,
        signer
      );

      const claimLive = await depositoryContract.claimStatus();

      if (claimLive === 1) {
        setIsClaimLive(true);
        const shareData = await getRevShareMerkleData();

        const userShare = shareData.find((share) => share.id === address);

        // console.log(
        //   "userLeaf",
        //   getLeaf(
        //     "0x44293E8CF00D4393EbA76EDA3B7B9DdB5907BB6c",
        //     "90116600000000000"
        //   )
        // );

        // console.log(
        //   generateMerkleProof(
        //     shareData,
        //     "0x44293E8CF00D4393EbA76EDA3B7B9DdB5907BB6c",
        //     "90116600000000000"
        //   )
        // );

        // 0xfa81fd296ee2fc1d4d56b23b1b298763075d2e960151f363709b66dcf9dc157c   test leaf using values above

        // 0x6c4522f6e8e33e8bfb2889ca01f3b152b03d57b3613b22e9adec5185e89fc5ac   test proof using values above
        // 0x0050e983222d1c85c36a9c2c2468923112d3dd3c1e155dc67142061b7621ee43
        // 0x2e4055e17cb67e2aa9782558a680ac334bd449110877ef294674015ae85069df
        // 0x35e16f1d378dbd4b538e823e818aac9db0c9c46ed5b01fe6c63688042a646020
        // 0xd7d22021b7f853beaaa458fb1540f5736ad2a8abfa0762429b497c084379d87c
        // 0x9bc01dc41d08110ccb2658b4947b5f4d5c5eb325e84276afb07d91dddd2f7329
        // 0x7e8de6a2b533baea94c08097bff85ee0a6a0bc15152d89552a6b21b9391b28b0
        // 0xb97be4a0369cfe32ee64ecf0960e321cf1cd3b496b5a961446b5647d34950ff3
        // 0x2b4deb3cd0b8fa37cf79d41af1f1d930f0754d4af7870169390843b641178442
        // 0xe9590b547744320e5c2e6a235dc9c9b12c0665ff928deae42583f51d426ed81a
        // 0xbf398087935b24359d8e940f4880ddee2fdb0a36a53c6c43efc68687895bbd66

        if (userShare) {
          setIsWhitelisted(true);

          const isLeafClaimed = await depositoryContract.leafClaimed(
            getLeaf(userShare.id, userShare.share)
          );

          if (isLeafClaimed) {
            setShareAlreadyClaimed(true);
            setAmountToClaim("0.00");
          } else {
            setShareAlreadyClaimed(false);
            setAmountToClaim(userShare.share);
            setProof(
              generateMerkleProof(shareData, userShare.id, userShare.share)
            );
          }
        } else setIsWhitelisted(false);
      } else setIsClaimLive(false);

      const res = await getUserShareClaimEvents(address);

      if (res.length > 1) {
        const revenueArray = res.map((obj) => ({
          revenue: Number(formatEther(obj.args.amount)).toFixed(2),
        }));

        setChartData([...revenueArray].reverse());
      }

      const ethereumPriceResponse = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        // "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,USD"
        // "https://api.etherscan.io/api?module=stats&action=ethprice&apikey=4RIQ2G2VAEFUR8JUP6CV1IQRVDDDA7M49N"
      );
      setEthUsdPrice(ethereumPriceResponse.data.ethereum.usd);
      // setEthUsdPrice(ethereumPriceResponse.data.USD);

      // setEthUsdPrice(ethereumPriceResponse.data.result.ethusd);

      setClaimActivity(res);
      setLoaded(true);
    };

    if (!wrongNetwork) {
      fetchData();
    }
  }, [address, wrongNetwork]);

  // console.log(proof);

  const { config } = usePrepareContractWrite({
    ...DepositoryContract,
    functionName: "claimShare",
    args: [amountToClaim, proof],
    onSuccess(data) {
      setPrepareError(false);
      setErrorMsg("");
    },
    onError(error) {
      setPrepareError(true);
      setErrorMsg(error.shortMessage);
    },
  });

  const { write: ClaimShare, data: txnData } = useContractWrite({
    ...config,
    onSuccess(data) {
      setIsBtnLoading(true);
    },
    onError(error) {
      setIsBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  const { isSuccess } = useWaitForTransaction({
    hash: txnData?.hash,
    // onSuccess(data) {
    //   toast.success("Share successfully claimed");
    // },
    onError(error) {
      setIsBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  useEffect(() => {
    const handleSuccess = async () => {
      if (isSuccess) {
        const res = await getUserShareClaimEvents(address);

        if (res.length > 1) {
          const revenueArray = res.map((obj) => ({
            revenue: Number(formatEther(obj.args.amount)).toFixed(2),
          }));

          // setChartData(revenueArray);
          setChartData([...revenueArray].reverse());
        }

        setClaimActivity(res);

        setIsBtnLoading(false);
        setShareAlreadyClaimed(true);

        setAmountToClaim("0.00");

        toast.success("Share successfully claimed");
      }
    };
    handleSuccess();
  }, [isSuccess]);

  // console.log("reversed", [...chartData].reverse());
  // console.log("NOT REV", chartData);

  const toggleCurrency = () => {
    setShowInEth((prevShowInEth) => !prevShowInEth);
  };

  const handleClick = () => {
    if (prepareError) {
      toast.error(errorMsg);
      return;
    }

    setIsBtnLoading(true);
    ClaimShare?.();
  };

  // console.log("check if it added", chartData);

  return (
    <div className="mt-10">
      <div className="px-4 md:px-14  ">
        <div className="flex flex-col gap-4">
          <h1 className="text-light text-4xl font-semibold">Revenue Share</h1>
          <p className="text-darkgray font-normal">
            As a PNGVN token holder you are entitled to calim dividend payouts
            every quarter. Follow the onpage instructions to make you claim and
            enjoy your dividend rewards with PNGVN.
          </p>
        </div>

        {loaded ? (
          <div className="mt-20 mb-10 flex flex-wrap-reverse justify-center gap-12  2xl:gap-44 items-center">
            <div className=" md:h-[400px] md:w-[400px] h-[300px] w-[300px]  px-4 py-4 md:py-0 fadeUp flex flex-col ">
              <h1 className="text-xl text-grey">Available Claim</h1>

              <div className=" text-5xl md:text-8xl font-semibold flex flex-col text-center mt-24 text-light">
                <div>
                  {Number(amountToClaim) > 0
                    ? Number(formatEther(amountToClaim)).toFixed(3).toString()
                    : amountToClaim}{" "}
                  <span className="text-darkgray text-4xl">ETH</span>
                </div>
                <Button
                  onClick={handleClick}
                  disabled={
                    !isWhitelisted ||
                    shareAlreadyClaimed ||
                    isBtnLoading ||
                    !isClaimLive
                  }
                  className="bg-gold mt-6  w-[250px]   md:w-[300px] mx-auto"
                >
                  {!isClaimLive
                    ? "Claiming not live"
                    : !isWhitelisted
                    ? "not eligible"
                    : shareAlreadyClaimed
                    ? "share claimed"
                    : isBtnLoading
                    ? "claiming..."
                    : "claim"}
                </Button>
              </div>
            </div>

            <div className="md:h-[400px] md:w-[400px] h-[300px] w-[300px]">
              <h4 className="text-xl text-grey">Total user payouts</h4>
              {chartData.length > 1 ? (
                <button onClick={toggleCurrency} className="my-3">
                  {showInEth ? (
                    <div className="group flex flex-col text-start cursor-pointer">
                      <div className="flex items-center gap-1">
                        <Eth className="w-8 h-8 mt-3 text-light" />
                        <span className="text-4xl text-light mt-4 font-bold">
                          {parseFloat(
                            chartData.reduce(
                              (total, item) => total + parseFloat(item.revenue),
                              0
                            )
                          ).toFixed(2)}
                        </span>
                      </div>
                      <span className="font-normal text-purpleAccent group-hover:opacity-80">
                        Click to view in USD
                      </span>
                    </div>
                  ) : (
                    <div className="group flex flex-col items-start cursor-pointer">
                      <span className="text-4xl text-light mt-4 font-bold">
                        ${" "}
                        {parseFloat(
                          chartData.reduce(
                            (total, item) =>
                              total + parseFloat(item.revenue * ethUsdPrice),
                            0
                          )
                        ).toFixed(2)}
                      </span>
                      <span className="font-normal text-purpleAccent group-hover:opacity-80">
                        Click to view in ETH
                      </span>
                    </div>
                  )}
                </button>
              ) : (
                <div className="group flex flex-col text-start ">
                  <div className="flex items-center gap-1">
                    <span className="text-4xl text-light mt-4 font-bold">
                      Not enough data
                    </span>
                  </div>
                  <span className="font-normal text-purpleAccent ">
                    Chart will be available after a minimum of 2 claims
                  </span>
                </div>
              )}
              <ResponsiveContainer
                width="100%"
                height="60%"
                className={chartData.length > 1 ? "" : "opacity-30"}
              >
                <LineChart
                  data={chartData.length > 1 ? chartData : placeholderChartData}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 10,
                    bottom: 0,
                  }}
                >
                  <Line
                    type="monotone"
                    strokeWidth={2}
                    dataKey="revenue"
                    stroke="#9E712C"
                    activeDot={{
                      r: 6,
                      style: { fill: "#000000", opacity: 0.25 },
                    }}
                    // style={
                    //   {
                    //     stroke: "var(--theme-primary)",
                    //     "--theme-primary": `hsl(${
                    //       theme?.cssVars[mode === "dark" ? "dark" : "light"]
                    //         .primary
                    //     })`,
                    //   }
                    // }
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className="w-full h-[520px] flex justify-center items-center">
            <div class="loader"></div>
          </div>
        )}
      </div>
      <TransactionTable
        activity={claimActivity}
        loaded={loaded}
        title="All previous claims"
      />
    </div>
  );
};

export default RevenueShare;
