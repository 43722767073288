import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "sonner";

const firebaseConfig = {
  apiKey: "AIzaSyDNgCl7wB0noui5q-OxWUpa7Xfd09zAuo4",
  authDomain: "gng-dapp.firebaseapp.com",
  projectId: "gng-dapp",
  storageBucket: "gng-dapp.appspot.com",
  messagingSenderId: "798320814464",
  appId: "1:798320814464:web:e966f06360662ca36e7965",
  measurementId: "G-W7WGFHQN8N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const revShareData = [
  {
    id: "0x7667A06a7b0c7E3185da4FA4e452A9d1cd24D9dB",
    amount: "0.072003005",
  },
  {
    id: "0x4b27f0D553aDdB5f3Fe1787CD19DD9328Be9f503",
    amount: "0.0212000004",
  },
  {
    id: "0x0F552b7563018FCe9F99ddA2D7BcFc00500C849D",
    amount: "0.01410001000004",
  },
  {
    id: "0x55A8451173FD460B6E3CFaD1fF62E9217Ae0f7aD",
    amount: "0.01271020003",
  },
];

export const createRevShareMerkleData = async () => {
  try {
    const whitelistRef = collection(db, "whitelist");

    // Custom document ID
    const customDocId = "MerkleData";

    // Reference to the document with the custom ID
    const customDocRef = doc(whitelistRef, customDocId);

    // Write data to the document with the custom ID
    await setDoc(customDocRef, {
      revShareData: revShareData,
    });

    toast.success("Data written to Firestore");
  } catch (error) {
    toast.error("Error writing data to Firestore");
    console.error("Error adding document: ", error);
  }
};

export const getRevShareMerkleData = async () => {
  try {
    const whitelistRef = doc(db, "whitelist", "MerkleData");

    // Get the document snapshot
    const docSnapshot = await getDoc(whitelistRef);

    // Check if the document exists
    if (docSnapshot.exists()) {
      // Access the document data
      const revShareData = docSnapshot.data().revShareData;

      // Do something with the data
      // console.log("RevShare Data:", revShareData);

      // toast.success("Data retrieved from Firestore");
      return revShareData; // Return the data if needed
    } else {
      console.log("No such document exists!");
      toast.error("Document not found in Firestore");
      return null; // Or handle the case where the document doesn't exist
    }
  } catch (error) {
    // toast.error("Error retrieving data from Firestore");
    console.error("Error getting document: ", error);
    return null;
  }
};

export const getTokenClaimMerkleData = async () => {
  try {
    const tokenClaimRef = doc(db, "tokenclaim", "TokenClaimMerkleData");

    // Get the document snapshot
    const docSnapshot = await getDoc(tokenClaimRef);

    // Check if the document exists
    if (docSnapshot.exists()) {
      // Access the document data
      const tokenClaimData = docSnapshot.data().claimData;

      return tokenClaimData; // Return the data if needed
    } else {
      console.log("No such document exists!");
      toast.error("Document not found in Firestore");
      return null; // Or handle the case where the document doesn't exist
    }
  } catch (error) {
    // toast.error("Error retrieving data from Firestore");
    console.error("Error getting document: ", error);
    return null;
  }
};

export const updateRevShareMerkleData = async (newData) => {
  try {
    const whitelistRef = doc(db, "whitelist", "MerkleData");

    const newFields = {
      revShareData: newData,
    };

    // Use setDoc to completely overwrite the document data
    await updateDoc(whitelistRef, newFields);
    toast.success("Database successfully updated");
  } catch (error) {
    toast.error("Error editing document in Firestore");
    console.error("Error updating document: ", error);
  }
};
